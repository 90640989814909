import React, { useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

import { FormattedMessage } from '../../../util/reactIntl';
import { isListingPaymentModeLinkToAnotherWebPage } from '../../../util/data';
import { getDayOfWeek, getStartOf, HOUR_MS } from '../../../util/dates';
import { getTimeSlots, getAllTimeValues, getMonthlyTimeSlots } from './timeslotHelpers';
import { manageDisableScrolling } from '../../../ducks/ui.duck';
import { useShowListingPlayersQuery } from '../../../ducks/ListingApi';

import { IconSpinner, Modal, AvatarSmall, H4, H6 } from '../../../components';

import css from './BookingTimeForm.module.css';
import { isArrayLength } from '../../../util/genericHelpers';
import { getUserNameModal } from '../../../util/dataExtractors';

const weekdays = moment
  .localeData('en')
  .weekdaysShort()
  .map(d => d.toLowerCase());

const getAvailabilityPlanSeats = ({ timeZone, startDate, enhancedListing }) => {
  const entries = enhancedListing?.attributes?.availabilityPlan?.entries || [];
  const dayOfWeek = weekdays[getDayOfWeek(startDate, timeZone)];
  const seats = entries.find(entry => entry.dayOfWeek === dayOfWeek)?.seats;

  return seats;
};

const getAvailabilityExceptionsSeats = ({ startDate, availabilityExceptions }) => {
  if (!availabilityExceptions) {
    return null;
  }
  const availabilityException = availabilityExceptions.find(
    item => item.attributes.start === startDate.toISOString()
  );

  return availabilityException?.attributes?.seats;
};

const Seats = props => {
  const [playersListModalOpen, setPlayersListModalOpen] = useState(false);

  const {
    intl,
    timeZone,
    breakdownData,
    enhancedListing,
    enhancedListingIsLoading,
    enhancedListingIsError,
    monthlyTimeSlots,
    availabilityExceptions,
    availabilityExceptionsIsLoading,
    availabilityExceptionsIsError,
    onManageDisableScrolling,
    listingId,
    registeredPlayers,
    confirmPayment,
    playerAcceptedDateWise,
    playerAcceptedData,
    playerRegisteredDateWise,
    seatsTotal,
    paymentMode
  } = props;

  const { startDate } = breakdownData;
  const startTime = startDate.getTime();
  const currentMonth = getStartOf(startDate, 'month', timeZone);
  const {
    data: players = [],
    isLoading: playersIsLoading,
    isError: playersIsError,
  } = useShowListingPlayersQuery(
    {
      listingId: listingId.uuid,
      start: startDate?.toISOString(),
      end: new Date((startDate?.getTime() || Date.now()) + 1 * HOUR_MS).toISOString(),
    },
    {
      skip: !startDate,
      refetchOnMountOrArgChange: true,
    }
  );

  const weeklySeats = getAvailabilityPlanSeats({ timeZone, startDate, enhancedListing });
  const exceptionsSeats = getAvailabilityExceptionsSeats({ startDate, availabilityExceptions });

  const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(monthlyTimeSlots, currentMonth, timeZone);
  const timeSlotsOnSelectedDate = getTimeSlots(timeSlotsOnSelectedMonth, startDate, timeZone);

  const { selectedTimeSlot } = getAllTimeValues(
    intl,
    timeZone,
    timeSlotsOnSelectedDate,
    startDate,
    startTime,
    startDate
  );

  const freeSeats =
    selectedTimeSlot?.attributes?.seats ||
    (isArrayLength(timeSlotsOnSelectedDate) && timeSlotsOnSelectedDate[0]?.attributes?.seats) ||
    0;
  const seats = seatsTotal;

  const playersCount = seats - freeSeats;
  const validPlayersCount = Number.isInteger(playersCount) && playersCount >= 0 ? playersCount : 0;
  const percentageSeats = ((playerAcceptedDateWise ? playerAcceptedDateWise : 0) / seats) * 100;
  const openPlayersModal = e => {
    e.preventDefault();
    setPlayersListModalOpen(true);
  };

  const playersLink = (
    <button className={css.registeredPlayersLink} onClick={openPlayersModal}>
      <FormattedMessage
        id="BookingTimeForm.currentlyPlayers"
        values={{ playersCount: registeredPlayers ? registeredPlayers : 0 }}
      />
    </button>
  );

  const profilePath = user => `/u/${user.customerId}`;
  const playersRegisteredKey = isListingPaymentModeLinkToAnotherWebPage(enhancedListing)
    ? 'BookingTimeForm.maxPlayersRegister'
    : 'BookingTimeForm.currentlyPlayersRegistered';

  return (
    <>
      <H6 as="h3" className={css.registeredPlayersHeader}>
        <FormattedMessage id={ Array.isArray(paymentMode) && paymentMode.length ? "BookingTimeForm.registeredPlayers":"BookingTimeForm.externalLinkRegistered"} />
      </H6>
      {enhancedListingIsError || availabilityExceptionsIsError ? (
        <span className={css.sideBarError}>
          <FormattedMessage id="BookingTimeForm.fetchSeatsError" />
        </span>
      ) :Array.isArray(paymentMode) && paymentMode.length?(
        <div className={css.registeredPlayersText}>
          {enhancedListingIsLoading || availabilityExceptionsIsLoading ? (
            <IconSpinner className={css.registeredPlayersSpinner} />
          ) : (
            <div className={css.progressSection} onClick={openPlayersModal}>
              <div className={css.progressBarWrapper}>
                <div className={css.progressContainer}>
                  <div
                    className={css.progressBar}
                    style={{ width: `${percentageSeats}%` }}
                  />
                </div>
                <span className={css.progressText}>{playerAcceptedDateWise ? playerAcceptedDateWise : 0} / {seats}</span>
              </div>
              <div className={css.playersRegister}>
                <FormattedMessage id="BookingTimeForm.playerRegistered" />
                <div className={css.playerNumber}>{playerRegisteredDateWise ?? 0}</div>
              </div>
            </div>)}

        </div>
      ):seats}
      <hr className={css.totalDivider} />
      <Modal
        id="OrderPanel.playersList"
        isOpen={playersListModalOpen}
        onClose={() => setPlayersListModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.playersList}>
          <H4>
            <FormattedMessage id="BookingTimeForm.registeredPlayers" />
          </H4>
          {playersIsError && (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchPlayersError" />
            </span>
          )}
          {playersIsLoading && <IconSpinner className={css.registeredPlayersSpinner} />}
          {playerAcceptedData ? playerAcceptedData.data.map((user, index) => {
            const displayName = getUserNameModal(user);
            return (
              <div className={css.player} key={`user-${index}`}>
                <a href={profilePath(user)} target="_blank" rel="noreferrer">
                  {displayName}
                </a>
              </div>
            );
          }) : null}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const SeatsComponent = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Seats);

export default SeatsComponent;
